import { createSelector } from '@ngrx/store';
import { AppState } from "../app-state";
import { EventState } from "./event.reducer";
import { EventRegistration } from "./event.model";

export const selectEventState = (state: AppState) => state.event;

export const selectEvents = createSelector(
  selectEventState,
  (state: EventState) => state.events
);

export const selectEventById = (eventId: string) => createSelector(
  selectEventState,
  (state: EventState) => state.events.find(event => event.id === eventId)
);

export const selectEventError = createSelector(
  selectEventState,
  (state: EventState) => state.error
);

export const selectEventStatus = createSelector(
  selectEventState,
  (state: EventState) => state.status
);

export const areEventsLoaded = createSelector(
  selectEventState,
  state => state.status === 'succeeded'
);

export const selectRemoveFromEventStatus = createSelector(
  selectEventState,
  (state: EventState) => state.status
);

export const selectRemoveFromEventError = createSelector(
  selectEventState,
  (state: EventState) => state.error
);

export const selectRemoveFromEvent = (eventId: string, registrationId: string) => createSelector(
  selectEventState,
(state: EventState) => state.events[eventId].registrations.filter((registration: EventRegistration) => registration.id !== registrationId)
);
