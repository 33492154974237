import { Injectable } from '@angular/core';
import { AvailableRental, EventRegistrationRental } from "../state/available_rental/available_rental.model";
import { EVENTS_REGISTRATION_RENTALS_DB } from "./event.service";
import { EventRegistrationRentalsDTO } from "../state/event/event.model";
import { SupabaseService } from "./supabase.service";

export const AVAILABLE_RENTALS_DB = 'available_rentals';
export const EVENTS_REGISTRATIONS_RENTALS_DB = 'events_registrations_rentals';

@Injectable({ providedIn: 'root' })
export class RentalService {

  constructor(private supabase: SupabaseService) {
  }

  async getAvailableRentals(): Promise<AvailableRental[]> {
    return this.supabase.client
      .from(AVAILABLE_RENTALS_DB)
      .select('*')
      .then((result) => {
        return result.data;
      });
  }

  async getEventRegisteredRentals(registrationsId: string[] = []): Promise<EventRegistrationRental[]> {
    return this.supabase.client
      .from(EVENTS_REGISTRATIONS_RENTALS_DB)
      .select('*')
      .in('registration_id', registrationsId)
      .then((result) => {
        return result.data;
      });
  }

  async saveEventRegistrationRentals(eventRegistrationRentals: EventRegistrationRentalsDTO[]): Promise<EventRegistrationRental[]> {
    const result = await this.supabase.client
      .from(EVENTS_REGISTRATION_RENTALS_DB)
      .insert(eventRegistrationRentals)
      .select();
    return result.data;
  }

  async deleteEventRegistrationRentals(eventRegistrationId: string, ) {
    const result = await this.supabase.client
      .from(EVENTS_REGISTRATION_RENTALS_DB)
      .delete()
      .match({ registration_id: eventRegistrationId });
    return result.data;
  }


}
