import { Injectable } from '@angular/core';
import { Event, EventRegistrationDTO } from "../state/event/event.model";
import { SupabaseService } from "./supabase.service";

export const EVENTS_DB = 'events';
export const EVENTS_REGISTRATION_DB = 'events_registrations';
export const EVENTS_REGISTRATION_RENTALS_DB = 'events_registrations_rentals';
export const EVENTS_REGISTRATION_VIEW = 'registered_users_view';


@Injectable({ providedIn: 'root' })
export class EventService {

  constructor(private supabase: SupabaseService) {
  }

  async loadEvents(): Promise<Event[]> {
    const { data, error } = await this.supabase.client.from(EVENTS_DB).select(
      `*,
      registrations:events_registrations(
      *, rentals:events_registrations_rentals(*, details:available_rentals(name, price_per_rent))
      ),
      registered_user_count:registered_users_view(count),
      type:events_type(name, icon, color),
      place:places(name, map_link),
      approved_user_count:approved_users_view(count)`
    ).order('date', { ascending: false });
    if (error) throw error;
    return data || [];
  }

  async saveEvent(event: Event): Promise<undefined[]> {
    const { data, error } = await this.supabase.client.from(EVENTS_DB).upsert(event);
    if (error) throw error;
    return data || null;
  }

  async deleteEvent(eventId: string): Promise<void> {
    const { error } = await this.supabase.client.from(EVENTS_DB).delete().match({ id: eventId });
    if (error) throw error;
  }

  async loadEventRegistrations() {
    const result = await this.supabase.client
      .from(EVENTS_REGISTRATION_DB)
      .select(`*`)
    return result.data;
  }

  async loadEventRegistrationsView() {
    const result = await this.supabase.client
      .from(EVENTS_REGISTRATION_VIEW)
      .select(`*`)
    return result.data;
  }

  async saveEventRegistrations(eventRegistration: EventRegistrationDTO): Promise<EventRegistrationDTO[]> {
    const result = await this.supabase.client
      .from(EVENTS_REGISTRATION_DB)
      .upsert(eventRegistration)
      .select();
    return result.data;
  }

  async updateEventRegistrations(eventRegistrationId: string, eventRegistrationPayload: Partial<EventRegistrationDTO>): Promise<EventRegistrationDTO[]> {
    const result = await this.supabase.client
      .from(EVENTS_REGISTRATION_DB)
      .update(eventRegistrationPayload)
      .eq('id', eventRegistrationId)
      .select();
    return result.data;
  }

  async deleteEventRegistrations(eventRegistrationId: string): Promise<EventRegistrationDTO[]> {
    const { data, error } = await this.supabase.client
      .from(EVENTS_REGISTRATION_DB)
      .delete()
      .match({ id: eventRegistrationId })
      .select();
    if (error) throw error;
    return data || [];
  }

}
