import {createActionGroup, emptyProps, props } from '@ngrx/store';
import { Event, EventRegistrationDTO } from "./event.model";

export const eventActions =
  createActionGroup({source: 'Event', events: {
      loadEvents: emptyProps(),
      loadEventsSuccess: props<{ events: Event[] }>(),
      loadEventsFailure: props<{ error: string }>(),
      saveEvent: props<{ event: Event }>(),
      saveEventSuccess: props<{ event: Event }>(),
      saveEventFailure: props<{ error: string }>(),
      deleteEvent: props<{ eventId: string }>(),
      deleteEventSuccess: props<{ eventId: string }>(),
      deleteEventFailure: props<{ error: string }>(),
      removeFromEvent: props<{ eventId: string, registrationId: string }>(),
      removeFromEventSuccess: props<{ eventId: string, registration: EventRegistrationDTO }>(),
      removeFromEventFailure: props<{ error: string }>(),
      approveRegistration: props<{ eventId: string, registrationId: string }>(),
      approveRegistrationSuccess: props<{ eventId: string, registration: EventRegistrationDTO }>(),
      approveRegistrationFailure: props<{ error: string }>(),
      rejectRegistration: props<{ eventId: string, registrationId: string, rejectReason: string }>(),
      rejectRegistrationSuccess: props<{ eventId: string, registration: EventRegistrationDTO }>(),
      rejectRegistrationFailure: props<{ error: string }>(),
    }});
